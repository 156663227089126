import styled from "styled-components";

export const AppContainer = styled.div`
  div.header {
    height: 3.5rem;
  }
  
  div.body {
    height: calc(var(--app-height) - 3.5rem);
    overflow-x: scroll;
  }
`;

export const MainContainer = styled.div`
  height: 100%;
`;
