import { useEffect, useRef } from "react";

type EmojicomWidgetProps = {
  campaignId: string,
  overrideOptions: any,
  onSubmit: any
};

const EMBED_SCRIPT_ID = "emojicom-embed";

function loadEmojicom() {
  if (document.getElementById(EMBED_SCRIPT_ID)) {
    return;
  }

  const script = document.createElement("script");
  script.src = "https://cdn.emojicom.io/embed/widget.js";
  script.id = EMBED_SCRIPT_ID;
  script.async = true;
  document.head.appendChild(script);
}

function unloadEmojicom() {
  try {
    document.getElementById(EMBED_SCRIPT_ID)?.remove();
    delete window.emojicom;
  } catch (err) { 
    console.log(err);
  }
}

export default function EmojicomWidget(props: EmojicomWidgetProps) {
  const targetRef = useRef<any>();
  const widgetRef = useRef<any>();
  const emojicomAPI = useRef<any>(null);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    function getEmojicomAPI() {
      if (!emojicomAPI.current) {
        emojicomAPI.current = new Promise((resolve) => {
          window.emojicom = resolve;
        });

        loadEmojicom();
      }

      return emojicomAPI.current;
    }

    if (widgetRef.current) {
      widgetRef.current.destroy();
    }

    getEmojicomAPI().then((emojicomAPI: any) => {
      return emojicomAPI
        .createWidget({
          campaign: props.campaignId,
          target: targetRef.current,
          overrideOptions: props.overrideOptions,
          onSubmit: props.onSubmit
        })
        .then((widget: any) => {
          widgetRef.current = widget;
        });
    });

    return () => {
      unloadEmojicom();
    };
  }, [props.campaignId, props.overrideOptions, props.onSubmit]);

  return <div ref={targetRef} />;
}
