import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UIProvider } from "scallywag-ui";
import HelpProvider from "./contexts/Help/HelpProvider";
import SocketConnectionProvider from "./contexts/SocketConnection/SocketConnectionProvider";
import { IntlProvider } from 'react-intl';
import translations from "./internationalization/translations";
import DebugProvider from "./contexts/Debug/DebugProvider";
import LogRocket from 'logrocket';
import { getEnvBoolean } from './utils/env';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
  dsn: "https://233beb0838784239a0353ce9ad4366a2@o76093.ingest.sentry.io/6353424",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

declare global {
  interface Window {
    // eslint-disable-next-line
    emojicom?: { };
  }
}

if (getEnvBoolean('REACT_APP_LOGROCKET')) {
  LogRocket.init('phbuna/capture-n2phq');
}

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

ReactDOM.render(
  <React.StrictMode>
    <DebugProvider>
      <UIProvider theme="piratev2">
        <IntlProvider defaultLocale="en" locale="en" messages={(translations as any)["en"]}>
          <HelpProvider>
            <SocketConnectionProvider>
              <App />
            </SocketConnectionProvider>
          </HelpProvider>
        </IntlProvider>
      </UIProvider>
    </DebugProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
