import styled, { css } from "styled-components";

export const SidebarItem = styled.a<{ isHeading?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  padding: ${(props) => props.theme.space["md"]};
  background-color: ${(props) => props.theme.colors.neutral93};
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral75};

  ${(props) =>
    props.isHeading &&
    css`
      cursor: default;
      font-weight: 700;
      font-size: 20px;
      height: 4.5rem;
    `}

  > svg {
    transform: rotate(-90deg);
  }

  & > p {
    padding: 0;
    margin: 0;
    max-width: 80%;
    line-height: 20px;
  }
`;
