import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
  height: 3.5rem;
  display: flex;
  justify-content: space-between;

  padding: ${props => props.theme.space.md};

  background-color: #111;
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: ${props => props.theme.colors.primaryWhite};
  border-bottom: 1px solid ${props => props.theme.colors.neutral75};
`;

export const HeaderText = styled.div`
  font-weight: bold;
  align-self: center;
`;

export const MobileNavContainer = styled.div`
  display: flex;
  width: auto;
`;

export const MobileNavButton = styled.span<{ isOpen: boolean, icon?: string }>`
  display: block;
  width: 32px;
  height: 32px;
  transition: all 0.3s;
  margin-left: 8px;
  align-self: center;

  &:after,
  &:before {
    border-radius: 0px;
    display: block;
    transition: all 0.3s ease-in-out;
  }

  ${props =>
    props.isOpen &&
    css`
      &:before {
        transform: translateY(0.45rem) rotate(135deg);
        width: 
      }
      &:after {
        transform: translateY(-0.45rem) rotate(-135deg);
      }
    `
  }
  
  ${props =>
    props.icon === "help" && !props.isOpen ?
      css`
        &:after {
          background-color: none;
          border: 2px solid;
          border-color: ${props => props.theme.colors.primaryWhite};
          border-radius: 50%;
          content: "?";
          width: 32x;
          height: 32px;
          text-align: center;
          font-weight: 700;
        }
        &:before {
          display: none;
        }
      ` : css`
        &:after,
        &:before {
          background-color: ${props => props.theme.colors.primaryWhite};
          border-radius: 0px;
          content: "";
          height: 2px;
          margin: 8px 0 12px 0;
        }
      `
  }
`;
