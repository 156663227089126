import styled from "styled-components";

export const SessionInfoButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  text-align: start;
  color: ${props => props.theme.colors.utilityYellowBase}
`;
