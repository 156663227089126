import { useHelp } from '../../contexts/Help/HelpProvider';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'scallywag-ui';

const CustomerSupport = () => {
  const { helpState, helpActions } = useHelp();

  const channels = [
    {
      name: 'WhatsApp',
      link: 'https://wa.link/pirate',
    },
    {
      name: 'Web Chat',
      link: 'https://support.pirate.com/hc/en-gb',
    },
  ];

  return (
    <>
      <Styled.SupportModalBackdrop visibility={helpState.customerSupportVisibility} />
      <Styled.SupportModalWindow visibility={helpState.customerSupportVisibility}>
        <Styled.SidebarItem isHeading>
          <FormattedMessage id={`help.customer_support.heading`} />
          <Styled.CloseButton onClick={() => helpActions.setCustomerSupportVisibility(false)}>
            <Icon
              icon="cross"
              fill="neutral93"
              stroke="neutral93"
              size={16}
            />
          </Styled.CloseButton>
        </Styled.SidebarItem>
        {
          channels.map(channel => {
            return (
              <Styled.SidebarItem
                href={channel.link}
                target="_blank"
                key={channel.name}
              >
                <p>{channel.name}</p>
                <Icon
                  icon="chevron"
                  fill="primaryWhite"
                  stroke="primaryWhite"
                  size={16}
                />
              </Styled.SidebarItem>
            );
          })
        }
      </Styled.SupportModalWindow>
    </>
  );
};

export default CustomerSupport;
