import { createContext, FC, useContext, useEffect, useState } from "react";
import { SessionContextType } from "./types";
import { useSocketConnection } from "../SocketConnection/SocketConnectionProvider";
import { adjustedNow } from '../../utils/helpers';
import { KroolConnectionState } from '../../socket/useKroolWebsocket';

const SessionContext = createContext<SessionContextType>({} as SessionContextType);
SessionContext.displayName = 'SessionContext';

const SessionProvider: FC = ({ children }) => {
  const { socketState } = useSocketConnection();
  const [sessionEnd, setSessionEnd] = useState<number>();
  const [sessionStart, setSessionStart] = useState<number>();
  const [sessionCountdowns, setSessionCountdowns] = useState<{start?: number, end?: number}>({});
  const [sessionInProgress, setSessionInProgress] = useState<boolean>(false);
  const [isSessionStarting, setIsSessionStarting] = useState(false);
  const [studioName, setStudioName] = useState("Studio");

  // On initial page load, populate the sessionTimestamps array from the response to the initial GET request
  useEffect(() => {
    if (socketState.lastMessage !== null) {
      const message = socketState.lastMessage;
      if (message !== undefined && 'session_start' in message && 'session_end' in message) {
        setSessionStart(message['session_start']);
        setSessionEnd(message['session_end']);
      }

      if (message !== undefined && 'studio' in message) {
        setStudioName(message['studio']);
      }
    }
  }, [
    setSessionEnd,
    setSessionStart,
    socketState.lastMessage
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (sessionStart && sessionEnd) {
        setSessionCountdowns(s => ({start: sessionStart! - adjustedNow(socketState.timeOffset), end: sessionEnd! - adjustedNow(socketState.timeOffset)}));
        setSessionInProgress(socketState.socketConnectionStatus === KroolConnectionState.OPEN && sessionStart <= adjustedNow(socketState.timeOffset) && sessionEnd > adjustedNow(socketState.timeOffset));
      }
    }, 1000);

    return function cleanup() {
      clearInterval(timer);
    };
  }, [
    sessionStart,
    sessionEnd,
    socketState.socketConnectionStatus,
    socketState.timeOffset
  ]);

  useEffect(() => {
    if (socketState.tokenInvalidatedTimestamp) {
      // Stop session in progress, if token has been invalidated
      setSessionEnd(socketState.tokenInvalidatedTimestamp);
    }
  }, [
    socketState.tokenInvalidatedTimestamp
  ]);

  const sessionActions = { setIsSessionStarting };
  const sessionState = { isSessionStarting, sessionCountdowns, sessionEnd, sessionInProgress, sessionStart, studioName };
  const value = { sessionActions, sessionState };
  
  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error(`This component must be used within the SessionProvider`);
  }
  return context;
}

export default SessionProvider;

