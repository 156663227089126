import Header from "./layout/Header/Header";
import * as Styled from "./styled";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { FlexibleContainer } from "scallywag-ui";
import CustomerSupport from "./components/CustomerSupport/CustomerSupport";
import Tracking from "./tracking/Tracking";

const App = () => {
  Tracking.init();
  return (
    <Styled.AppContainer>
      <BrowserRouter>
        <FlexibleContainer height={"vhMax"}>
          <FlexibleContainer className={"header"}>
            <Header />
          </FlexibleContainer>
          <FlexibleContainer className={"body"}>
            <Styled.MainContainer>
              <Router />
            </Styled.MainContainer>
          </FlexibleContainer>
          <CustomerSupport />
        </FlexibleContainer>
      </BrowserRouter>
    </Styled.AppContainer>
  );
};

export default App;
