import * as Styled from './styled';
import {useTheme} from "scallywag-ui";

type WaveformBarProps = {
  barHeight: number;
  disabled: boolean;
  silent: boolean;
}

const WaveformBar = (props: WaveformBarProps): JSX.Element => {
  // Injecting dynamic styles into the WaveformBar component using the style attribute as opposed to passing props to
  // the Styled.WaveformBar component, as otherwise styled-components will generate a new css class for each and every
  // styling permutation of each Bar - height, top, backgroundColor, and combinations thereof.
  const theme = useTheme("piratev2");

  const backgroundColor = props.disabled
    ? theme.colors.neutral75
    : props.silent
      ? theme.colors.utilityYellowBase
      : props.barHeight >= 100
        ? theme.colors.utilityRedBase
        : theme.colors.primaryWhite;

  const top = props.barHeight >= 100 ? 0 : 50 - props.barHeight / 2;

  const barHeight = props.barHeight >= 100 ? 100 : props.barHeight;

  return (
    <Styled.WaveformBar style={{
      backgroundColor: `${backgroundColor}`,
      top: `${top}%`,
      height: `${barHeight}%`
    }} />
  );
};

export default WaveformBar;
