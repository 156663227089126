import * as Styled from './styled';
import MobileNavDropdown from "./MobileNavDropdown/MobileNavDropdown";
import HelpSidebar from "../Sidebar/HelpSidebar";
import { useHelp } from '../../contexts/Help/HelpProvider';
import { SessionStateTracking } from '../../tracking/SessionStateTracking';
import { useSocketConnection } from '../../contexts/SocketConnection/SocketConnectionProvider';

const Header = () => {
  const { socketState } = useSocketConnection();
  const { helpState, helpActions } = useHelp();

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderText>
        PIRATE.COM
      </Styled.HeaderText>
      <Styled.MobileNavContainer>
        <Styled.MobileNavButton
          isOpen={helpState.helpSidebarOpen}
          icon="help"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (helpState.page !== "") {
              helpActions.setPage("");
            } else {
              if (socketState.token) {
                if (!helpState.helpSidebarOpen) SessionStateTracking.logOpenHelpTopics(socketState.token);
              }
              helpActions.setHelpSidebarOpen(prev => !prev);
            }
          }}
        />
        <MobileNavDropdown isOpen={helpState.helpSidebarOpen}>
          <HelpSidebar />
        </MobileNavDropdown>
      </Styled.MobileNavContainer>
    </Styled.HeaderContainer>
  );
};

export default Header;
