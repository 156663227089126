import styled, { css } from "styled-components";

export const Container = styled.div<{ isVisible: boolean }>`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.colors.neutral93};
  font-family: Arial, sans-serif;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.primaryWhite};
  z-index: 12;
  transition: all 0.375s ease-in-out;
  transform-origin: bottom;
  opacity: 0;
  left: 100vw;
  visibility: hidden;

  ${(props) =>
    props.isVisible &&
    css`
      visibility: visible;
      z-index: 12;
      left: 0;
      opacity: 1;
    `}
`;

export const BackButton = styled.div`
  > svg {
    align-self: flex-start;
    margin-top: 2px;
    margin-right: 18px;
    margin-bottom: ${(props) => props.theme.space["xl"]};
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.neutral93};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.space["3xl"]};
  -webkit-overflow-srolling: touch;
  overflow-y: scroll;

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    padding-top: ${props => props.theme.space["xl"]};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: ${(props) => props.theme.space["xl"]};

  > h2 {
    margin: 0;
    margin-left: ${(props) => props.theme.space["xl"]};
  }
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;


  a {
    text-decoration: underline;
   }
`;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    max-height: 240px;
  }
`;
