import React from "react";
import * as Styled from "./styled";
import { useRecorder } from "../../contexts/Recorder/RecorderProvider";
import { useSession } from "../../contexts/Session/SessionProvider";
import { FormattedMessage } from "react-intl";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";
import { useSocketConnection } from "../../contexts/SocketConnection/SocketConnectionProvider";

type RecordButtonProps = {
  setIsTrackSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecordButton = (props: RecordButtonProps) => {
  const { socketState } = useSocketConnection();
  const { sessionState } = useSession();
  const { actions, state } = useRecorder();

  function processRecord() {
    if (state.isRecording) {
      props.setIsTrackSaved(true);
      props.setUploadVisibility(true);
      if (socketState.token) {
        SessionStateTracking.logFinishRecording(socketState.token);
      }
    } else {
      if (socketState.token) {
        SessionStateTracking.logStartRecording(socketState.token);
      }
    }

    actions.toggleRecording();
  }

  return (
    <Styled.RecordButtonContainer disabled={!sessionState.sessionInProgress}>
      <Styled.RecordButton
        disabled={!sessionState.sessionInProgress}
        isRecording={state.isRecording}
        onClick={() => processRecord()}
      >
        <span>
          {state.isRecording ? (
            <FormattedMessage id="session.stop" />
          ) : (
            <FormattedMessage id="session.record" />
          )}
        </span>
      </Styled.RecordButton>
    </Styled.RecordButtonContainer>
  );
};

export default RecordButton;
