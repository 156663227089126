import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { FlexibleContainer } from "scallywag-ui";
import { SessionEndContainer, BookMoreLink } from "./styled";
import { routes } from "../../Router";
import config from "../../config";
import EmojiCom from "../../components/Emojicom/Emojicom";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";
import { useSocketConnection } from "../../contexts/SocketConnection/SocketConnectionProvider";

const SessionEnd = (): JSX.Element => {
  const sessionEndLink = () => {
    if (config.useStagingUrls)
      return 'https://staging.book.pirate.com/j3llyf1sh/?filter_studio_type=2';
    else
      return 'https://book.pirate.com/?filter_studio_type=2';
  };

  const history = useHistory();

  const handleSubmit = () => {
    history.push(routes.feedback);
  };
  const { socketState } = useSocketConnection();
  const handleBookingMore = () =>{
    SessionStateTracking.logBookMoreTime("No token");
    setTimeout(() => {
      window.open(sessionEndLink());
    }, 500);
  };
  return (
    <SessionEndContainer>
      <FlexibleContainer
        display={"flex"}
        height={"full"}
        flexDirection={"column"}
        margin={"xl"}
        textAlign={"start"}
        justifyContent={"space-between"}
        justifyItems={"space-around"}
      >
        <div>
          <h2>
            <FormattedMessage id="session.info.session_end.heading" />
          </h2>

          <FormattedMessage id="session.info.session_end.subheading" />

          <h3>
            <FormattedMessage id="session.info.session_end.book_heading" />
          </h3>
          <BookMoreLink onClick={handleBookingMore} >
            <FormattedMessage id="session.info.session_end.book_link" />
          </BookMoreLink>

        </div>
        <EmojiCom campaignId="fhUbi2C3wdAoWDhrOZl1" onSubmit={handleSubmit} overrideOptions={{ themePreset: "dark" }} />
      </FlexibleContainer>
    </SessionEndContainer>
  );
};

export default SessionEnd;
