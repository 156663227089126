import { Tracking } from '.';

// SLR-1069
// disconnected is a network change. 
const logDisconnectedFromPirateWifi = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Notification',
    action: 'Connect journey',
    label: 'Disconnected Notification',
  });
};

const logReconnectToPirateWifi = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Connect journey',
    label: 'Attempt connect',
  });
};

// SLR-1070 offline
const logOfflineStatus = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'notification',
    action: 'connection error',
    label: 'Offline notification'
  });
};
// SLR-1071 session about to start
const logSessionAboutToStart = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'notification',
    action: 'Connection journey',
    label: 'Pre-session notification'
  });
};

// SLR-1072
const logPreSession = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'notification',
    action: 'pre-session',
    label: ''
  });
};
const logRecordReady = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Page',
    action: 'Record',
    label: 'Record ready'
  });
};
const logStartRecording = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Record',
    label: 'Start recording'
  });
};
const logFinishRecording = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Record',
    label: 'Stop recording'
  });
};
const logViewTracks = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Record',
    label: 'Check recordings',
  });
};

// SLR-1073
const logSessionEndTracking = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Page',
    action: 'Record',
    label: 'Session End',
  });
};
const logBookMoreTime = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Book',
    label: 'Book more time',
  });
};

// SLR-1074 
const logInvalidToken = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Notification',
    action: 'Connection error',
    label: 'No booking found notification'
  });
};

// SLR-1075
const logNoAudioDetected = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Notification',
    action: 'Audio Error',
    label: `No audio detected notification`
  });
};
const logOpenGuide = (token: string, page: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Help',
    label: page
  });
};

// SLR-1076
const logOpenHelpTopics = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Page',
    action: 'Help',
    label: 'Help menu'
  });
};

const logViewHelpTopic = (token: string, title: string): void => {
  Tracking.pushEvent({
    token,
    category: 'Page',
    action: 'Help',
    label: title.split('_').join(' '),
  });
};
const logRequestCustomerSupport = (token: string): void => {
  Tracking.pushEvent({
    token,
    category: 'CTA',
    action: 'Help',
    label: ''
  });
};



export const SessionStateTracking = {
  logSessionAboutToStart,
  logDisconnectedFromPirateWifi,
  logOfflineStatus,
  logSessionEndTracking,
  logReconnectToPirateWifi,
  logOpenHelpTopics,
  logViewHelpTopic,
  logRequestCustomerSupport,
  logNoAudioDetected,
  logOpenGuide,
  logBookMoreTime,
  logPreSession,
  logRecordReady,
  logStartRecording,
  logFinishRecording,
  logViewTracks,
  logInvalidToken
};
