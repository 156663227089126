import styled from "styled-components";

export const VolumeIndicatorContainer = styled.div`
  display: flex;
  width: calc(100vw - ${props => props.theme.space["3xl"]} + 2px - ${props => props.theme.space["3xl"]});
  text-align: center;
  justify-content: space-between;
  margin-top: ${props => props.theme.space["md"]};
  margin-bottom: 4px;
`;

export const VUBar = styled.span<{ backgroundColor: string }>`
  height: 4px;
  width: 12.5%;
  margin: 0 1px;
  display: flex;
  background-color: ${props => props.backgroundColor};
`;

export const VolumeIndicatorLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const VolumeIndicatorLabel = styled.span<{ color: string }>`
  font-size: 12px;
  color: ${props => props.color};
`;
