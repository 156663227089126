import { useEffect, useState, useCallback, ReactNode } from "react";
import { FlexibleText } from "scallywag-ui";
import config from "../../config";
import * as Styled from "./styled";
import { useSocketConnection } from "../../contexts/SocketConnection/SocketConnectionProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { KroolConnectionState } from "../../socket/useKroolWebsocket";
import { msTo12HourFormatAMPM } from "../../utils/helpers";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";
import SubHeaderButton from "../SubHeaderButton/SubHeaderButton";
import { useSession } from "../../contexts/Session/SessionProvider";
import { useRecorder } from "../../contexts/Recorder/RecorderProvider";

type SessionConnectionInfoProps = {
  studioName: string;
  setIsRecordActive: React.Dispatch<React.SetStateAction<boolean>>;
  isSessionStarting: boolean;
  startingTime?: number;
};

export enum ConnectionStatusEnum {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  SOCKETERROR = "socketError",
  RECOVERY = "recovery",
}

const SessionConnectionInfo = ({
  studioName,
  // TODO: should this be renamed to setIsSessionActive
  setIsRecordActive,
  startingTime,
  isSessionStarting,
}: SessionConnectionInfoProps): JSX.Element => {
  const intl = useIntl();
  const { sessionState } = useSession();
  const { socketState } = useSocketConnection();
  const recorderState = useRecorder().state;
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatusEnum>(ConnectionStatusEnum.CONNECTED);
  const [badgeHeading, setBadgeHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [connectionState, setConnectionState] = useState<KroolConnectionState>(
    KroolConnectionState.UNINSTANTIATED
  );

  useEffect(() => {
    if (config.isCloud) {
      setIsRecordActive(false);
      setConnectionStatus(ConnectionStatusEnum.DISCONNECTED);
      if (socketState.socketConnectionStatus !== connectionState) {
        setConnectionState(connectionState);
      }
    } else if (
      socketState.socketConnectionStatus !== KroolConnectionState.OPEN
    ) {
      setIsRecordActive(false);
      if (recorderState.isRecording) {
        setConnectionStatus(ConnectionStatusEnum.RECOVERY);
      } else {
        setConnectionStatus(ConnectionStatusEnum.SOCKETERROR);
      }
      if (socketState.socketConnectionStatus !== connectionState) {
        setConnectionState(socketState.socketConnectionStatus);
      }
    } else {
      setConnectionStatus(ConnectionStatusEnum.CONNECTED);
      if (socketState.socketConnectionStatus !== connectionState) {
        setConnectionState(connectionState);
      }
      if (isSessionStarting) {
        setIsRecordActive(false);
      } else {
        setIsRecordActive(true);
      }
    }
  }, [
    socketState.socketConnectionStatus,
    setIsRecordActive,
    isSessionStarting,
    socketState.token,
    connectionState,
    recorderState.isRecording,
  ]);

  useEffect(() => {
    if (connectionStatus === ConnectionStatusEnum.CONNECTED) {
      setBadgeHeading(studioName);
      if (isSessionStarting && startingTime) {
        setSubHeading(
          intl.formatMessage(
            { id: "session.info.starting_soon" },
            { starts_at: msTo12HourFormatAMPM(startingTime) }
          )
        );
      } else {
        setSubHeading("");
      }
    } else {
      setBadgeHeading(
        intl.formatMessage({
          id: `session.info.connection.${connectionStatus}.badge_heading`,
        })
      );
      setSubHeading(
        intl.formatMessage({
          id: `session.info.connection.${connectionStatus}.subheading`,
        })
      );
    }
  }, [
    connectionStatus,
    setBadgeHeading,
    setSubHeading,
    intl,
    studioName,
    isSessionStarting,
    startingTime,
  ]);

  useEffect(() => {
    if (socketState.token) {
      if (!config.isCloud) {
        if (
          badgeHeading !== "Offline" &&
          badgeHeading !== "Disconnected from Pirate WiFi" &&
          (!sessionState.sessionCountdowns.start ||
            sessionState.sessionCountdowns.start <= 0) &&
          (!sessionState.sessionCountdowns.end ||
            sessionState.sessionCountdowns.end >= 0)
        ) {
          // this is the happy path
          SessionStateTracking.logRecordReady(socketState.token);
        }
        if (badgeHeading === "Offline" || badgeHeading === "Recovery mode") {
          SessionStateTracking.logOfflineStatus(socketState.token);
        }
      }
      if (config.isCloud && badgeHeading === "Disconnected from Pirate WiFi") {
        SessionStateTracking.logDisconnectedFromPirateWifi(socketState.token);
      }
    }
  }, [badgeHeading, socketState.token]);

  const handleSubheaderButtonClick = useCallback(() => {
    if (socketState.token) {
      SessionStateTracking.logReconnectToPirateWifi(socketState.token);
    }
    window.location.reload();
  }, [socketState.token]);

  return (
    <Styled.SessionInfoContainer>
      <Styled.SessionInfoBadge status={connectionStatus}>
        <Styled.ConnectionIndicator status={connectionStatus} />
        {badgeHeading}
      </Styled.SessionInfoBadge>
      {connectionStatus === ConnectionStatusEnum.RECOVERY && (
        <FlexibleText display="block" color="utilityGreenBase" marginTop="xl">
          <FormattedMessage id="session.info.connection.recovery.still_recording" />
        </FlexibleText>
      )}
      {subHeading && <p dangerouslySetInnerHTML={{ __html: subHeading }} />}
      {(connectionStatus === ConnectionStatusEnum.SOCKETERROR ||
        connectionStatus === ConnectionStatusEnum.DISCONNECTED) && (
        <SubHeaderButton
          connectionStatus={connectionStatus}
          handleSubheaderButtonClick={handleSubheaderButtonClick}
        />
      )}
      {connectionStatus === ConnectionStatusEnum.DISCONNECTED && (
        <Styled.SessionInfoLink
          href="https://support.pirate.com/hc/en-gb/articles/11936859850129--Disconnected-from-Pirate-WiFi-in-CAPTURE-AUDIO"
          target="_blank"
        >
          <FormattedMessage
            id={"session.info.connection.disconnected.help_button"}
          />
        </Styled.SessionInfoLink>
      )}
    </Styled.SessionInfoContainer>
  );
};

export default SessionConnectionInfo;
