import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ visible: boolean, saved: boolean }>`
  width: 100%;
  position: absolute;
  bottom: -156px;
  right: 0;
  z-index: 2;

  transition: all 0.375s ease-in-out;
  transform-origin: bottom;
  visibility: hidden;
  opacity: 0;

  background-color: ${props => props.saved ? props.theme.colors.utilityGreenBase : props.theme.colors.primaryWhite};

  ${props => props.visible && css`
    visibility: visible;
    opacity: 1;
    bottom: 0;
  `};
`;

export const Backdrop = styled.div<{ visible: boolean }>`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: transparrent;
  z-index: 3;
  visibility: ${props => props.visible ? "visible" : "hidden"};
`;

export const TextZone = styled.div`
  padding-top: ${props => props.theme.space["2xl"]};
  padding-bottom: ${props => props.theme.space["2xl"]};
  padding-left: ${props => props.theme.space["3xl"]};
  padding-right: 100px;
  text-align: start;
  font-size: 16px;
  color: ${props => props.theme.colors.neutral93};

  & > h2 {
    margin: 0;
    padding-bottom: 4px;
  }

  @media(max-width: 375px) {
    padding-right: 96px;
    font-size: 14px;

    & > h1 {
      font-size: 20px;
    }
  }
`;
