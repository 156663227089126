import styled, { css } from "styled-components";

export const Panel = styled.div<{ isOpen: boolean }>`
  padding: 0;
  padding-bottom: 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutral75};
  display: flex;
  position: absolute;
  visibility: hidden;
  top: 3.5rem;
  left: 100vw;
  width: 100vw;
  z-index: 10;
  transform-origin: bottom;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: ${props => props.theme.colors.neutral100};
  transition: all 0.375s ease-in-out;
  opacity: 0;
  padding-top: 0px;

  ${props =>
  props.isOpen &&
  css`
      left: 0;
      visibility: visible;
      opacity: 1;
    `}
`;
