import TagManager from "react-gtm-module";
import config from "../config";
import * as Sentry from "@sentry/browser";

enum EventTypes {
  CustomEvent = "Custom Event"
}

const init = (): void => {
  TagManager.initialize({
    gtmId: config.googleTagManager.id
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
const pushEvent = (data: {}): void => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event: EventTypes.CustomEvent,
        ...data
      }
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const pushData = (data: {}): void => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        ...data
      }
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

const tracking = { init, pushEvent, pushData, EventTypes };
export default tracking;
