export function getVariable(key: string, transform: (value: any) => any, defaultValue?: any): any {
  const value = process.env[key];
  if (value) return transform(value);
  // Allow an empty string as a legit value... because react router
  if (defaultValue !== null && defaultValue !== undefined) return defaultValue;

  throw new Error(
    `Environment variable not found. Either set '${key}' in your .env file or pass in a default value`
  );
}

export function getEnvBoolean(key: string, defaultValue?: boolean): boolean {
  return getVariable(
    key,
    value => {
      return value === "true";
    },
    defaultValue
  );
}

export function getEnvString(key: string, defaultValue?: string): string {
  return getVariable(
    key,
    value => {
      return value;
    },
    defaultValue
  );
}

export function getEnvNumber(key: string, defaultValue?: number): number {
  return getVariable(
    key,
    value => {
      return Number(value);
    },
    defaultValue
  );
}

type Environments = "preprod" | "staging" | "production" | "dev";

export const isEnv = (env: Environments) => process.env.REACT_APP_ENV === env;

export const getGTMContainer = () => {
  const id = isEnv("production") ? "GTM-NN7HV9G" : "GTM-NTMNTW4";
  return getEnvString("GOOGLE_TAG_MANAGER_CODE", id);
};
