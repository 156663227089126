import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRecorder } from "../../contexts/Recorder/RecorderProvider";
import * as Styled from "./styled";

type UploadContaineProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  saved: boolean;
  setSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadContainer = (props: UploadContaineProps) => {
  const intl = useIntl();
  const { state } = useRecorder();
  const [numberOfTracks, setNumberOfTracks] = useState(0);

  useEffect(() => {
    setNumberOfTracks(
      state.filteredTimestamps
        .filter(timestamp => timestamp[0] && timestamp[1])
        .length
    );
  }, [state.filteredTimestamps]);

  function closeContainer() {
    props.setVisible(false);
    setTimeout(() => props.setSaved(false), 500);
  }

  return (
    <>
      <Styled.Wrapper visible={props.visible} saved={props.saved}>
        <Styled.TextZone>
          <h2>
            {
              props.saved
                ? intl.formatMessage({ id: "session.upload.heading_saved" }, { track_number: state.recordingId })
                : intl.formatMessage({ id: "session.upload.heading" }, { track_list_length: numberOfTracks })
            }
          </h2>
          {intl.formatMessage({ id: "session.upload.text" })}
        </Styled.TextZone>
      </Styled.Wrapper>
      <Styled.Backdrop visible={props.visible} onClick={closeContainer} />
    </>
  );
};

export default UploadContainer;
