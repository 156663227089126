import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'scallywag-ui';
import { useHelp } from '../../contexts/Help/HelpProvider';
import { SessionStateTracking } from '../../tracking/SessionStateTracking';
import * as Styled from './styles';
import {useSocketConnection} from "../../contexts/SocketConnection/SocketConnectionProvider";

type Props = {
  topicType: "troubleshooting" | "tutorials"
}

const RenderHelpTopics = ({ topicType }: Props): JSX.Element => {
  const { socketState } = useSocketConnection();

  const helpTopics = {
    tutorials: [
      {
        topic: "how_does_it_work",
        image: "",
      },
      {
        topic: "how_do_i_get_started",
        image: "",
      },
      {
        topic: "how_can_i_tell_if_its_setup_correctly",
        image: "",
      },
      {
        topic: "how_do_i_check_recordings_levels",
        image: "",
      },
    ],
    troubleshooting: [
      {
        topic: "is_it_recording_correctly",
        image: "",
      },
      {
        topic: "what_happens_if_my_screen_locks",
        image: "",
      },
      {
        topic: "will_this_record_onto_my_phone",
        image: "",
      },
      {
        topic: "will_it_use_my_data",
        image: "",
      },
      {
        topic: "no_audio_detected_message_is_being_displayed",
        image: "/assets/help/no-audio.gif",
      },
      {
        topic: "too_loud_is_being_displayed",
        image: "",
      },
      {
        topic: "where_do_i_access_my_recordings",
        image: "",
      },
      {
        topic: "no_booking_found",
        image: "",
      },
      {
        topic: "how_long_are_my_recordings_are_stored",
        image: "",
      },
      {
        topic: "its_not_working_how_do_i_get_help",
        image: "",
      },
    ],
  };

  const { helpActions } = useHelp();

  return (
    <>
      {helpTopics[topicType].map((helpPage, index) =>
        <Styled.SidebarItem
          key={`${helpPage}${index}`}
          onClick={(e) => {
            e.preventDefault();
            helpActions.setPage(helpPage.topic);
            helpActions.setImageUrl(helpPage.image);
            if (socketState.token) {
              SessionStateTracking.logViewHelpTopic(socketState.token, helpPage.topic);
            }
          }}
        >
          <p>
            <FormattedMessage id={`help.${helpPage.topic}.heading`} />
          </p>
          <Icon
            icon="altChevron"
            fill="primaryWhite"
            stroke="primaryWhite"
            size={16}
          />
        </Styled.SidebarItem>
      )}
    </>
  );
};

export default RenderHelpTopics;
