import { createContext, FC, useContext, useState } from "react";
import { HelpContextType } from './types';

const HelpContext = createContext<HelpContextType>({} as HelpContextType);
HelpContext.displayName = 'HelpContext';

const HelpProvider: FC = ({ children }) => {
  const [page, setPage] = useState("");
  const [customerSupportVisibility, setCustomerSupportVisibility] = useState(false);
  const [helpSidebarOpen, setHelpSidebarOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const helpState = { page, customerSupportVisibility, helpSidebarOpen, imageUrl };
  const helpActions = { setPage, setCustomerSupportVisibility, setHelpSidebarOpen, setImageUrl };
  const value = { helpState, helpActions };
  
  return (
    <HelpContext.Provider value={value}>
      {children}
    </HelpContext.Provider>
  );
};

export function useHelp() {
  const context = useContext(HelpContext);
  if (context === undefined) {
    throw new Error(`This component must be used within the HelpProvider`);
  }
  return context;
}

export default HelpProvider;
