export const msToTimeDuration = function (duration: number) {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return (
    (
      hours <= 0
        ? ""
        : String(hours).padStart(2, "0") + ":"
    ) +
    (
      minutes <= 0
        ? "00"
        : String(minutes).padStart(2, "0")
    ) +
    ":" +
    (
      seconds <= 0
        ? "00"
        : String(seconds).padStart(2, "0")
    )
  );
};

export const msToTimeDurationStr = function (duration: number) {
  const minutes = Math.ceil((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return `${hours}hr ${minutes}m`;
};

export const msToDateStr = function (timestamp: number) {
  return new Date(timestamp).toLocaleDateString("en-GB", { day: 'numeric', month: 'short', year: 'numeric' });
};

// time synced with k-rool
export const adjustedNow = (offset: number) => Date.now() + offset;

export const msTo12HourFormatAMPM = function (timestamp: number) {
  const date = new Date(timestamp);
  return date.toLocaleString([], { hour: 'numeric', minute: 'numeric' });
};
