import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SocketMessage } from "../contexts/SocketConnection/types";
import { SessionTimestamps } from "../contexts/Recorder/types";

export enum KroolConnectionState {
  UNINSTANTIATED = 0,
  OPEN = 1,
  CLOSED = 2,
}

type KroolCallbacks = {
  onclose?: (event: CloseEvent) => void;
  onerror?: (event: WebSocketEventMap["error"]) => void;
  onmessage?: (event: WebSocketEventMap["message"]) => void;
  onopen?: (event: WebSocketEventMap["open"]) => void;
};

export const useKroolWebsocket = (
  socketUrl: string,
  token: string,
  callbacks: KroolCallbacks,
  shouldConnect: boolean
) => {
  const webSocketRef = useRef<WebSocket | null>(null);
  const callbacksCache = useRef<KroolCallbacks>(callbacks);
  callbacksCache.current = callbacks;

  const [readyState, setReadyState] = useState<KroolConnectionState>(
    KroolConnectionState.UNINSTANTIATED
  );
  const [lastMessage, setLastMessage] = useState<SocketMessage | undefined>(
    undefined
  );
  const timerId = useRef(0);

  const openSocket = useCallback(() => {
    webSocketRef.current = new WebSocket(socketUrl + token);
    webSocketRef.current.onopen = (event) => {
      setReadyState(KroolConnectionState.OPEN);
      if (
        callbacksCache.current !== undefined &&
        callbacksCache.current.onopen !== undefined
      ) {
        callbacksCache.current.onopen(event);
      }
    };
    webSocketRef.current.onerror = (errorEvent) => {
      setReadyState(KroolConnectionState.CLOSED);
      if (
        callbacksCache.current !== undefined &&
        callbacksCache.current.onerror !== undefined
      ) {
        callbacksCache.current.onerror(errorEvent);
      }
    };
    webSocketRef.current.onmessage = (messageEvent) => {
      if (
        callbacksCache.current !== undefined &&
        callbacksCache.current.onmessage !== undefined
      ) {
        callbacksCache.current.onmessage(messageEvent);
      }
      setLastMessage(JSON.parse(messageEvent.data));
      // The websocket connection is sometimes lost without the client closing the socket.
      // This timeout closes the socket (and therefore opens a new one) after 10s of inactivity.
      clearTimeout(timerId?.current);
      timerId.current = setTimeout(() => {
        closeSocket();
      }, 10000);
    };
    webSocketRef.current.onclose = (closeEvent) => {
      setReadyState(KroolConnectionState.CLOSED);
      if (
        callbacksCache.current !== undefined &&
        callbacksCache.current.onclose !== undefined
      ) {
        callbacksCache.current.onclose(closeEvent);
      }
    };
  }, [callbacksCache, socketUrl, token]);

  const closeSocket = () => {
    webSocketRef.current?.close();
  };

  const sendMessage = useCallback((message: string) => {
    if (webSocketRef.current?.readyState === KroolConnectionState.OPEN) {
      webSocketRef.current?.send(message);
    }
  }, []);

  type JsonMessage = {
    id: string;
    get?: boolean;
    action?: string;
  };

  const sendJsonMessage = useCallback(
    (message: JsonMessage) => {
      sendMessage(JSON.stringify(message));
    },
    [sendMessage]
  );

  useEffect(() => {
    if (shouldConnect && socketUrl !== "" && token !== "") {
      openSocket();
    }
  }, [shouldConnect, socketUrl, token, openSocket]);

  return {
    readyState,
    openSocket,
    closeSocket,
    sendMessage,
    sendJsonMessage,
    lastMessage,
  };
};
