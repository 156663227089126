import * as Styled from "./styled";
import { useDebug } from "../../contexts/Debug/DebugProvider";
import { useEffect, useRef } from "react";

export const Debug = () => {
  const consoleDivRef = useRef<HTMLDivElement>(null);
  const { debugState, debugActions } = useDebug();

  useEffect(() => {
    if (consoleDivRef) {
      debugActions.setConsoleElement(consoleDivRef);
    }
  }, [debugActions, consoleDivRef]);

  useEffect(() => {
    if (consoleDivRef.current) {
      consoleDivRef.current.innerHTML = debugState.consoleData;
    }
  }, [debugState.consoleData]);

  return (
    <Styled.DebugPageContainer>
      <Styled.DebugWindow ref={consoleDivRef} />
    </Styled.DebugPageContainer>
  );
};
