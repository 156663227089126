import styled from "styled-components";

export const SessionEndContainer = styled.div`
  height: 100%;
  overflow: hidden;

  a {
    text-decoration: underline;
  }

  h2, h3 {
    margin-bottom: ${props => props.theme.space["sm"]} ;
  }
`;

export const BookMoreLink = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  padding: 0;
`;
