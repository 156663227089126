import React, { forwardRef } from "react";
import * as Styled from "./styled";

type MobileNavDropdownProps = {
  children: React.ReactChild;
  isOpen: boolean;
};

const MobileNavDropdown = forwardRef((props: MobileNavDropdownProps, ref: any) => {

  return (
    <Styled.Panel ref={ref} isOpen={props.isOpen}>
      {props.children}
    </Styled.Panel>
  );
});
MobileNavDropdown.displayName = 'mobile nav dropdown';
export default MobileNavDropdown;
