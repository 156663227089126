import styled, { css } from "styled-components";
import { SpaceScaleValues, ThemeColors } from "scallywag-ui";

export const SupportModalBackdrop = styled.div<{ visibility?: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;

  z-index: 13;
  transition: all 0.375s ease-in-out;
  
  visibility: hidden;
  opacity: 0;

  ${props => props.visibility && css`
    visibility: visible;
    opacity: 0.5;
  `}
`;

export const SupportModalWindow = styled.div<{ visibility?: boolean }>`
  position: absolute;
  width: 100%;
  bottom: -1000px;
  left: 0px;

  z-index: 15;
  transition: all 0.375s ease-in-out;
  transform-origin: bottom;

  visibility: hidden;
  opacity: 0;

  ${props => props.visibility && css`
    visibility: visible;
    opacity: 1;
    bottom: 0;
  `}
`;

export const CloseButton = styled.div`
  padding: 18px 18px 18px 18px;
`;

export const SupportModalButton = styled.button<{ color: ThemeColors, margin?: SpaceScaleValues }>`
  font-family: Arial, sans-serif;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors[props.color]};
  text-decoration: underline;
  text-align: start;
  margin: ${props => props.margin ? props.margin : `${props.theme.space["3xl"]} 0`};

  padding: 0;

  background-color: ${(props) => props.theme.colors.neutral93};
  border: none;
`;

export const SidebarItem = styled.a<{ isHeading?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;

  cursor: pointer;
  height: 3.5rem;

  background-color: ${props => props.theme.colors.neutral93};
  border-bottom: 1px solid ${props => props.theme.colors.neutral75};
  padding: ${props => props.theme.space.md};
  white-space: nowrap;

  ${props => props.isHeading && css`
    padding-right: 0px;
    cursor: default;
    font-weight: 700;
    font-size: 16px;
    height: 4.5rem;
    background-color: ${props => props.theme.colors.primaryWhite};
    color: ${props => props.theme.colors.neutral93};
  `}

  > svg {
    transform: rotate(-90deg);
  }
`;

export const RightArrow = styled.div`
  border-top: ${props => props.theme.space["2xs"]} solid transparent;
  border-bottom: ${props => props.theme.space["2xs"]} solid transparent;
  border-left: ${props => props.theme.space.sm} solid white;
`;
