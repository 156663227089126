import { useEffect, useState } from "react";
import * as Styled from "./styled";
import { FlexibleContainer, useTheme } from "scallywag-ui";
import { useRecorder } from "../../contexts/Recorder/RecorderProvider";

type VolumeIndicatorTypes = {
  isActive: boolean;
};

const VolumeIndicator = (props: VolumeIndicatorTypes) => {
  const { state } = useRecorder();
  const [currentValue, setCurrentValue] = useState(0);
  const [isTooQuiet, setIsTooQuiet] = useState(false);
  const [isGoodVolume, setIsGoodVolume] = useState(false);
  const [isTooLoud, setIsTooLoud] = useState(false);
  const { colors } = useTheme("piratev2");

  useEffect(() => {
    const currentValue = state.audioData[state.audioData.length - 1];
    if (typeof currentValue === "number") {
      setCurrentValue(currentValue);
    }
  }, [state.audioData]);

  useEffect(() => {
    const tooQuietFrames = state.audioData.filter(vu => vu > 0 && vu <= 12.5);
    const goodVolumeFrames = state.audioData.filter(vu => vu > 12.5 && vu <= 75);
    const tooLoudFrames = state.audioData.filter(vu => vu > 76);

    setIsTooQuiet(tooQuietFrames.length > state.audioData.length / 2 && !state.isSilent && props.isActive);
    setIsGoodVolume(goodVolumeFrames.length > state.audioData.length / 2 && !state.isSilent && props.isActive);
    setIsTooLoud(tooLoudFrames.length > state.audioData.length / 2 && !state.isSilent && props.isActive);
  }, [state.audioData, state.isSilent, props.isActive]);

  return (
    <>
      <FlexibleContainer display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Styled.VolumeIndicatorContainer>
          <Styled.VUBar backgroundColor={
            props.isActive && currentValue >= 4
              ? currentValue <= 12.5
                ? colors.utilityGreen700
                : colors.utilityGreenBase
              : colors.neutral75
          }
          />
          <Styled.VUBar backgroundColor={props.isActive && currentValue > 12.5 ? colors.utilityGreenBase : colors.neutral75} />
          <Styled.VUBar backgroundColor={props.isActive && currentValue > 25 ? colors.utilityGreenBase : colors.neutral75} />
          <Styled.VUBar backgroundColor={props.isActive && currentValue > 37.5 ? colors.utilityGreenBase : colors.neutral75} />
          <Styled.VUBar backgroundColor={props.isActive && currentValue > 50 ? colors.utilityGreenBase : colors.neutral75} />
          <Styled.VUBar backgroundColor={props.isActive && currentValue > 62.5 ? colors.utilityYellowBase : colors.neutral75} />
          <Styled.VUBar backgroundColor={props.isActive && currentValue > 75 ? colors.utilityYellowBase : colors.neutral75} />
          <Styled.VUBar backgroundColor={props.isActive && currentValue >= 100 ? colors.utilityRedBase : colors.neutral75} />
        </Styled.VolumeIndicatorContainer>
      </FlexibleContainer>
      <Styled.VolumeIndicatorLabelContainer>
        <Styled.VolumeIndicatorLabel color={isTooQuiet ? colors.utilityGreen700 : colors.neutral75}>TOO QUIET</Styled.VolumeIndicatorLabel>
        <Styled.VolumeIndicatorLabel color={isGoodVolume ? colors.utilityGreenBase : colors.neutral75}>GOOD</Styled.VolumeIndicatorLabel>
        <Styled.VolumeIndicatorLabel color={isTooLoud ? colors.utilityRedBase : colors.neutral75}>TOO LOUD</Styled.VolumeIndicatorLabel>
      </Styled.VolumeIndicatorLabelContainer>
    </>
  );
};

export default VolumeIndicator;
