import * as Styled from "./styled";
import { Icon, FlexibleText, FlexibleContainer } from "scallywag-ui";
import { useHelp } from "../../contexts/Help/HelpProvider";
import { useIntl } from "react-intl";
import CustomerSupportButton from "../../components/CustomerSupport/CustomerSupportButton";

const Help = (props: { isVisible: boolean, imageUrl: string }) => {
  const { helpState, helpActions } = useHelp();
  const intl = useIntl();

  const heading = helpState.page ? intl.formatMessage({ id: `help.${helpState.page}.heading` }) : "";
  const body = helpState.page ? intl.formatMessage({ id: `help.${helpState.page}.body` }) : "";

  return (
    <Styled.Container isVisible={props.isVisible}>
      <Styled.Wrapper>
        <Styled.Header>
          <Styled.BackButton onClick={() => helpActions.setPage("")}>
            <Icon
              icon="arrowLeft"
              fill="primaryWhite"
              stroke="primaryWhite"
              size={20}
            />
          </Styled.BackButton>
          <h2>{heading}</h2>
        </Styled.Header>

        <Styled.Body>
          <FlexibleText dangerouslySetInnerHTML={{ __html: body }}>
          </FlexibleText>
          <FlexibleContainer
            display={'flex'}
            flexDirection={'column-reverse'}
            paddingTop={'3xl'}
            textAlign={'center'}
            height="full"
            alignItems='center'
            justifyContent="space-between"
            paddingBottom={'2xl'}
          >
            <CustomerSupportButton />
            {props.imageUrl && <Styled.Image src={props.imageUrl} alt="" />}
          </FlexibleContainer>
        </Styled.Body>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default Help;
