import styled from "styled-components";

export const WaveformBarContainer = styled.div`
  position: relative;
  height: 100%;
  max-height: 192px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const WaveformBar = styled.span`
  align-self: flex-start;
  margin: 0.1rem;
  
  width: calc(100vw / 110);
  
  position: relative;
  min-height: 1%;
  
  display: inline-table;
`;

export const WaveformWarning = styled.div`
  font-size: 16px;
  color: ${props => props.theme.colors.utilityYellowBase};
`;

export const WaveformHeading = styled.span`
  position: absolute;
  top: calc(50% - 16px - 24px);
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
`;

export const WaveformLink = styled.span`
  position: absolute;
  bottom: calc(50% - 16px - 24px);
  width: 100%;
  text-decoration: underline;
`;
