import * as Styled from "./styled";
import WaveformBar from "./WaveformBar";
import { FlexibleContainer } from "scallywag-ui";
import { useRecorder } from "../../contexts/Recorder/RecorderProvider";
import { useSession } from "../../contexts/Session/SessionProvider";
import { FormattedMessage } from "react-intl";
import { useHelp } from "../../contexts/Help/HelpProvider";
import { useEffect } from "react";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";
import { useSocketConnection } from "../../contexts/SocketConnection/SocketConnectionProvider";

type WaveformTypes = {
  isActive: boolean;
}

const Waveform = (props: WaveformTypes) => {
  const { state } = useRecorder();
  const { sessionState } = useSession();
  const { helpActions } = useHelp();

  function RenderNoAudioWarning() {
    const { socketState } = useSocketConnection();
    useEffect(()=>{
      if (socketState.token) {
        SessionStateTracking.logNoAudioDetected(socketState.token);
      }
    },[]);
    return (
      <Styled.WaveformWarning>
        <Styled.WaveformHeading>
          <FormattedMessage id={"session.info.no_audio.heading"} />
        </Styled.WaveformHeading>
        <Styled.WaveformLink
          onClick={() => {
            helpActions.setPage("no_audio_detected_message_is_being_displayed");
            helpActions.setImageUrl("/assets/help/no-audio.gif");
            helpActions.setHelpSidebarOpen(true);
            if (socketState.token) {
              SessionStateTracking.logOpenGuide(socketState.token, '/No audio detected');
            }
          }}
        >
          <FormattedMessage id={"session.info.no_audio.link"} />
        </Styled.WaveformLink>
      </Styled.WaveformWarning>
    );
  }

  return (
    <FlexibleContainer
      width={"full"}
      display={"flex"}
      flexDirection={"column"}
      flexBasis={"content"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Styled.WaveformBarContainer>
        { (state.isRecording && sessionState.sessionInProgress && state.isSilent) && RenderNoAudioWarning() }
        {
          state.audioData.map((value, index) => (
            <WaveformBar
              key={index}
              barHeight={(props.isActive && state.isRecording) ? value : 0}
              disabled={(!state.isRecording)}
              silent={sessionState.sessionInProgress && state.isSilent}
            />
          ))
        }
      </Styled.WaveformBarContainer>
    </FlexibleContainer>
  );
};

export default Waveform;
