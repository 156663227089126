import * as Styled from "./styled";
import { useRecorder } from "../../contexts/Recorder/RecorderProvider";
import { FormattedMessage } from "react-intl";

type TrackInfoProps = {
  isActive: boolean;
};

const TrackInfo = (props: TrackInfoProps) => {
  const { state } = useRecorder();

  return (
    <Styled.TrackInfoContainer className={!props.isActive ? "disabled" : undefined}>
      <strong>
        <FormattedMessage
          id={"session.tracks.recording_x"}
          values={{ x: state.recordingId }}
        />
      </strong>
      <span className={(props.isActive && state.isRecording) ? "recording" : undefined}>
        {state.recordingDuration}
      </span>
    </Styled.TrackInfoContainer>
  );
};

export default TrackInfo;
