import * as Styled from "./styled";
import { useHelp } from "../../contexts/Help/HelpProvider";
import { FormattedMessage } from "react-intl";
import Help from "../../pages/Help/Help";
import { useEffect, useState } from "react";
import RenderHelpTopics from "../../components/RenderHelpTopics/RenderHelpTopics";

const HelpSidebar = (): JSX.Element => {
  const { helpState } = useHelp();
  const [helpPageVisibility, setHelpPageVisibility] = useState(false);

  useEffect(() => {
    setHelpPageVisibility(helpState.page !== "");
  }, [helpState.page]);

  return (
    <Styled.SidebarContainer>
      <Help isVisible={helpPageVisibility} imageUrl={helpState.imageUrl} />
      <Styled.SidebarItem isHeading>
        <FormattedMessage id="help.tutorials" />
      </Styled.SidebarItem>
      <RenderHelpTopics topicType="tutorials" />
      <Styled.SidebarItem isHeading>
        <FormattedMessage id={"help.troubleshooting"} />
      </Styled.SidebarItem>
      <RenderHelpTopics topicType="troubleshooting" />
    </Styled.SidebarContainer>
  );
};

export default HelpSidebar;
