import { useEffect, useState } from "react";
import * as Styled from './styled';
import SessionConnectionInfo from "../../components/SessionConnectionInfo/SessionConnectionInfo";
import VolumeIndicator from "../../components/VolumeIndicator/VolumeIndicator";
import Waveform from "../../components/Waveform/Waveform";
import TrackInfo from "../../components/TrackInfo/TrackInfo";
import RecordButton from "../../components/RecordButton/RecordButton";
import Unauthorized from "../../components/Unauthorized/Unauthorized";
import UploadContainer from "../../components/UploadContainer/UploadContainer";
import { useSocketConnection } from "../../contexts/SocketConnection/SocketConnectionProvider";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";
import { useSession } from "../../contexts/Session/SessionProvider";
import { FlexibleContainer } from "scallywag-ui";
import { usePageVisibility } from "react-page-visibility";
import config from "../../config";

const Record = () => {
  const { sessionActions, sessionState } = useSession();
  const { socketState } = useSocketConnection();
  const isPageActive = usePageVisibility();
  const sessionEndUrl = `https://${config.useStagingUrls ? "staging." : ""}studio.pirate.com/session-end`;
  const [isUploadContainerVisible, setIsUploadContainerVisible] = useState(false);
  const [isTrackSaved, setIsTrackSaved] = useState(false);
  const [isRecordActive, setIsRecordActive] = useState(false);
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const [hasFiredAwaitingSessionTag, setHasFiredAwaitingSessionTag] = useState(false);
  const [hasFiredSessionEndTag, setHasFiredSessionEndTag] = useState(false);

  useEffect(() => {
    if (
      sessionState.sessionCountdowns.start &&
      sessionState.sessionCountdowns.start > 0
    ) {
      sessionActions.setIsSessionStarting(true);
      if (!hasFiredAwaitingSessionTag) {
        setHasFiredAwaitingSessionTag(true);
        if (socketState.token) {
          SessionStateTracking.logSessionAboutToStart(socketState.token);
        }
      }
    } else {
      sessionActions.setIsSessionStarting(false);
    }
  }, [
    hasFiredAwaitingSessionTag,
    sessionState.sessionCountdowns.start,
    sessionActions.setIsSessionStarting,
    socketState.token,
  ]);

  useEffect(() => {
    if (
      sessionState.sessionCountdowns.end &&
      sessionState.sessionCountdowns.end <= 1
    ) {
      setIsSessionEnded(true);
      if (!hasFiredSessionEndTag){
        if (socketState.token) {
          SessionStateTracking.logSessionEndTracking(socketState.token);
        }
        setHasFiredSessionEndTag(true);
      }
    }
  }, [hasFiredSessionEndTag, sessionState.sessionCountdowns.end, setIsSessionEnded, socketState.sessionId, socketState.token]);

  useEffect(() => {
    if (isSessionEnded && isPageActive) {
      window.location.replace(sessionEndUrl);
    }
  }, [isSessionEnded, isPageActive, sessionEndUrl]);

  useEffect(() => {
    if (socketState.error === "unauthorized" && !isSessionEnded) {
      if (socketState.token) {
        SessionStateTracking.logInvalidToken(socketState.token);
      }
    }
  }, [isSessionEnded, socketState.error, socketState.token]);

  const handleOpenTracks = () => {
    if (socketState.token) {
      SessionStateTracking.logViewTracks(socketState.token);
    }
    setIsUploadContainerVisible(true);
  };

  return (
    <>
      <Styled.RecordPageContainer>
        <FlexibleContainer
          height={"full"}
          display={"flex"}
          flexDirection={"column"}
        >
          {socketState.error === "unauthorized" && !isSessionEnded
            ? <Unauthorized />
            : <>
                {/* booking info */}
                <FlexibleContainer
                  height={"auto"}
                  display={"flex"}
                  marginHorizontal={"3xl"}
                  marginTop={"3xl"}
                >
                  <SessionConnectionInfo
                    studioName={sessionState.studioName}
                    setIsRecordActive={setIsRecordActive}
                    isSessionStarting={sessionState.isSessionStarting}
                    startingTime={sessionState.sessionStart}
                  />
                </FlexibleContainer>
                <FlexibleContainer
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  marginHorizontal={"3xl"}
                  marginTop={"xl"}
                >
                  <TrackInfo isActive={isRecordActive} />
                  <VolumeIndicator isActive={isRecordActive} />
                </FlexibleContainer>
                {/* waveform */}
                <FlexibleContainer
                  height={"auto"}
                  display={"flex"}
                  flex={1}
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <Waveform isActive={isRecordActive} />
                </FlexibleContainer>
                {/* uploads */}
                <FlexibleContainer height={"auto"}>
                  <FlexibleContainer
                    height={"auto"}
                    marginTop={"none"}
                    margin={"3xl"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <RecordButton
                      setIsTrackSaved={setIsTrackSaved}
                      setUploadVisibility={setIsUploadContainerVisible}
                    />
                    {isRecordActive && (
                      <Styled.UploadButton onClick={handleOpenTracks}>
                        <svg
                          width="36"
                          height="33"
                          viewBox="0 0 36 33"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M31.8753 8.55742C31.0952 6.5623 29.1826 5.2499 27.0001 5.2499C26.34 5.2499 25.6877 5.37734 25.0725 5.61747C23.9551 2.30996 20.8126 0 17.2502 0C13.8077 0 10.7852 2.08488 9.56255 5.24992C6.99014 5.34001 4.88268 7.28995 4.55242 9.79486C1.98001 10.1398 0 12.3449 0 14.9999C0 17.8949 2.3552 20.2498 5.24992 20.2498H9.00004V32.2496C9.00004 32.662 9.33748 32.9995 9.74994 32.9995H26.2498C26.6622 32.9995 26.9996 32.662 26.9996 32.2496L27 20.2498H29.9999C33.3074 20.2498 36 17.5572 36 14.2496C36 11.6471 34.3276 9.35972 31.8751 8.55711L31.8753 8.55742ZM25.5 31.5003H10.5005V18.0002H14.2506C14.6631 18.0002 15.0005 17.6628 15.0005 17.2503V13.5002H25.5008L25.5 31.5003ZM11.558 16.5L13.5004 14.5576V16.5H11.558ZM30 18.75H27.0001V12.7498C27.0001 12.3374 26.6627 11.9999 26.2502 11.9999H14.2496C14.152 11.9999 14.0547 12.0225 13.9646 12.0599C13.8745 12.0972 13.792 12.15 13.7173 12.225L9.21725 16.725C9.14976 16.7925 9.08981 16.8751 9.05214 16.9724C9.02232 17.0549 8.99972 17.1525 8.99972 17.2499V18.75H5.24959C3.17942 18.75 1.49947 17.07 1.49947 14.9998C1.49947 12.9297 3.17942 11.2497 5.24959 11.2497C5.66205 11.2497 5.99949 10.9123 5.99949 10.4998C5.99949 8.42965 7.67944 6.74969 9.74961 6.74969C9.84723 6.74969 9.93701 6.75723 10.0346 6.76476C10.3796 6.78736 10.6948 6.56983 10.7996 6.23961C11.6848 3.40484 14.2798 1.4998 17.2499 1.4998C20.43 1.4998 23.2048 3.74981 23.8575 6.86238C23.9099 7.10251 24.075 7.30497 24.3001 7.40229C24.5251 7.4999 24.7875 7.47731 24.99 7.34233C25.5974 6.95247 26.2948 6.75001 26.9999 6.75001C28.6573 6.75001 30.0974 7.80751 30.5774 9.38268C30.6524 9.63003 30.8549 9.82527 31.1098 9.88523C33.1049 10.4025 34.4999 12.1949 34.4999 14.2499C34.4999 16.7326 32.4825 18.7499 29.9999 18.7499L30 18.75Z"
                            fill={isUploadContainerVisible ? "black" : "white"}
                          />
                          <path
                            d="M18.1348 17.265C17.9173 17.2277 17.6923 17.2876 17.5199 17.4301C17.3473 17.5651 17.25 17.7751 17.25 18.0002V24.8852C17.0174 24.8026 16.7625 24.7502 16.4998 24.7502C15.2624 24.7502 14.2498 25.7629 14.2498 27.0002C14.2498 28.2376 15.2624 29.2502 16.4998 29.2502C17.7371 29.2502 18.7498 28.2376 18.7498 27.0002V18.9827C19.7398 19.3352 21.1724 20.1228 21.0073 21.6602C20.9625 22.0727 21.2622 22.4403 21.675 22.4852C22.0875 22.5225 22.455 22.2303 22.5 21.8175C22.8151 18.9001 19.785 17.5575 18.1348 17.265ZM16.4997 27.7501C16.0873 27.7501 15.7498 27.4126 15.7498 27.0002C15.7498 26.5877 16.0873 26.2503 16.4997 26.2503C16.9122 26.2503 17.2496 26.5877 17.2496 27.0002C17.2499 27.4126 16.9122 27.7501 16.4997 27.7501Z"
                            fill={isUploadContainerVisible ? "black" : "white"}
                          />
                        </svg>
                      </Styled.UploadButton>
                    )}
                    <UploadContainer
                      visible={isUploadContainerVisible}
                      setVisible={setIsUploadContainerVisible}
                      saved={isTrackSaved}
                      setSaved={setIsTrackSaved}
                    />
                  </FlexibleContainer>
                </FlexibleContainer>
              </>

          }
        </FlexibleContainer>
      </Styled.RecordPageContainer>
    </>
  );
};

export default Record;
