import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomerSupportButton from '../CustomerSupport/CustomerSupportButton';
import * as Styled from './styled';

type Props = {
  connectionStatus: string;
  handleSubheaderButtonClick: () => void
}

const SubHeaderButton = ({ connectionStatus, handleSubheaderButtonClick }: Props): JSX.Element => {
  return (
    <>
      {connectionStatus === "socketError"
      ? <CustomerSupportButton color={"accentBase"} margin={"none"} />
      : (
          <Styled.SessionInfoButton onClick={handleSubheaderButtonClick}>
            <FormattedMessage id={"session.info.connection.disconnected.button"} />
          </Styled.SessionInfoButton>
        )
      }
    </>
  );
};

export default SubHeaderButton;
