import { getEnvBoolean, getEnvNumber, getEnvString, getGTMContainer } from "./utils/env";

const config = {
  isCloud: getEnvBoolean("REACT_APP_IS_CLOUD", false),
  useStagingUrls: getEnvBoolean("REACT_APP_USE_STAGING_URLS", false),
  debugMode: getEnvBoolean("REACT_APP_DEBUG_MODE", false),
  jetsam: {
    host: getEnvString("REACT_APP_JETSAM_HOST")
  },
  siteSlug: getEnvString("REACT_APP_SITE_SLUG"),
  websocket: {
    base_url: getEnvString("REACT_APP_WS_SERVER_BASE_URL", ""),
    secure: getEnvBoolean("REACT_APP_WS_SECURE", true),
    port: getEnvNumber("REACT_APP_WS_SERVER_PORT")
  },
  googleTagManager: {
    id: getGTMContainer()
  }
};

export default config;
