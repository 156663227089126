import styled, { css } from "styled-components";
import { ConnectionStatusEnum } from "./SessionConnectionInfo";

export const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;

  p {
    margin-top: ${(props) => props.theme.space["xl"]};
  }
`;

export const SessionInfoBadge = styled.div<{
  status: ConnectionStatusEnum;
}>`
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  padding-right: 8px;
  background-color: ${(props) => props.theme.colors.neutral100};
  border-radius: 12px;

  ${(props) =>
    props.status === "connected" &&
    css`
      color: ${props.theme.colors.neutral50};
      border: 1px solid ${props.theme.colors.neutral80};
    `}

  ${(props) =>
    props.status === "recovery" &&
    css`
      color: ${props.theme.colors.neutral6};
      border: 1px solid ${props.theme.colors.utilityGreenBase};
    `}

  ${(props) =>
    props.status === "disconnected" &&
    css`
      color: ${props.theme.colors.neutral6};
      border: 1px solid ${props.theme.colors.utilityYellowBase};
    `}

    ${(props) =>
    props.status === "socketError" &&
    css`
      color: ${props.theme.colors.neutral6};
      border: 1px solid ${props.theme.colors.accentBase};
    `}
`;

export const SessionInfoHeader = styled.div<{ warnLevel?: number }>`
  font-size: ${(props) => props.theme.fontSizes.lg};

  @media only screen and (max-width: 320px) {
    font-size: ${(props) => props.theme.fontSizes.md};
  }

  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: ${(props) => props.theme.space.xs};
  color: ${(props) =>
    props.warnLevel === 1
      ? props.theme.colors.utilityYellowBase
      : props.warnLevel === 2
      ? props.theme.colors.utilityRedBase
      : props.theme.colors.primaryWhite};

  & > .icon-container {
    display: inline-block;
    padding: 0rem 0.2rem;
  }

  @media only screen and (max-width: 320px) {
    & > .icon-container {
      > .warning-icon {
        transform: scale(75%) translate(0, 0.2rem);
      }
    }
  }

  & > .icon-container > .warning-icon > .warning-circle-outer,
  .warning-bang-line,
  .warning-bang-dot {
    fill: ${(props) =>
      props.warnLevel === 1
        ? props.theme.colors.utilityYellowBase
        : props.warnLevel === 2
        ? props.theme.colors.utilityRedBase
        : props.theme.colors.primaryWhite};
    stroke: ${(props) =>
      props.warnLevel === 1
        ? props.theme.colors.utilityYellowBase
        : props.warnLevel === 2
        ? props.theme.colors.utilityRedBase
        : props.theme.colors.primaryWhite};
  }

  & > svg {
    margin: 0 ${(props) => props.theme.space["2xs"]};
  }
`;

const sessionInfoButtonStyles = css`
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  text-align: start;
  color: ${(props) => props.theme.colors.utilityYellowBase};
`;

export const SessionInfoButton = styled.button`
  ${sessionInfoButtonStyles}
`;

export const SessionInfoLink = styled.a`
  ${sessionInfoButtonStyles}
  padding-top: ${(props) => props.theme.space.sm};
`;

export const ConnectionIndicator = styled.span<{
  status: ConnectionStatusEnum;
}>`
  display: inline-block;
  margin-left: 8px;
  margin-right: 6px;
  height: ${(props) => props.theme.space["3xs"]};
  width: ${(props) => props.theme.space["3xs"]};
  border-radius: 100%;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.colors.utilityGreenBase};

  ${(props) =>
    (props.status === "connected" || props.status === "recovery") &&
    css`
      background-color: ${(props) => props.theme.colors.utilityGreenBase};
    `}

  ${(props) =>
    props.status === "disconnected" &&
    css`
      background-color: ${(props) => props.theme.colors.utilityYellowBase};
    `}

    ${(props) =>
    props.status === "socketError" &&
    css`
      background-color: ${(props) => props.theme.colors.accentBase};
    `}
`;
