import styled, { css } from "styled-components";

export const RecordButtonContainer = styled.div<{ disabled?: boolean }>`
  height: 66px;
  width: 136px;

  display: flex;
  align-items: center;
  justify-items: center;

  padding: 0;
  border-radius: 32px;
  border: 2px solid;
  border-color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral75
      : props.theme.colors.utilityRedBase};
`;

export const RecordButton = styled.button<{
  disabled?: boolean;
  isRecording?: boolean;
}>`
  height: 56px;
  width: 126px;
  padding: 0;
  margin: auto;
  border: none;
  border-radius: 32px;
  outline: none;
  background-color: ${(props) => props.theme.colors.neutral93};
  color: ${(props) => props.theme.colors.utilityRedBase};
  transition: all 0.3s ease-in-out;

  > span {
    height: 66px;
    width: 136px;
    border-radius: 32px;
    font-weight: bold;
    background-color: transparent;
  }

  ${(props) =>
    props.isRecording &&
    css`
      background-color: ${(props) => props.theme.colors.utilityRedBase};

      > span {
        color: ${(props) => props.theme.colors.neutral93};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      > span {
        color: ${props.isRecording
          ? props.theme.colors.neutral93
          : props.theme.colors.neutral75};
      }
      background-color: ${props.isRecording
        ? props.theme.colors.neutral75
        : props.theme.colors.neutral93};
    `}
`;
