import styled from "styled-components";

export const TrackInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes["xl"]};

  &.disabled {
    color: ${props => props.theme.colors.neutral75};
  }

  & > span.recording {
    color: ${props => props.theme.colors.utilityRedBase};
  }
`;
