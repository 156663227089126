import React from 'react';
import * as Styled from "./styled";
import { FormattedMessage } from "react-intl";
import CustomerSupportButton from "../../components/CustomerSupport/CustomerSupportButton";


const Unauthorized = (): JSX.Element => {
  return (
    <Styled.NoAuthorizedPage>
      <h2>
        <FormattedMessage id="session.unauthorized.heading" />
      </h2>
      <FormattedMessage id="session.unauthorized.text" />
      <CustomerSupportButton />
    </Styled.NoAuthorizedPage>
  );
};


export default Unauthorized;
