import styled from "styled-components";

export const RecordPageContainer = styled.div`
  height: 100%;
  text-align: center;
  overflow-x: hidden;

  a {
    text-decoration: underline;
  }

  h2 {
    margin-top: 0;
  }
`;

export const RecordButtonContainer = styled.div`
  display: block;
  margin: auto;
`;

export const NoAuthorizedPage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: ${props => props.theme.space["3xl"]};
`;

export const UploadButton = styled.button`
  height: 64px;
  width: 64px;
  padding: 0px;

  position: absolute;
  bottom: 33px;
  right: 23px;
  z-index: 3;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  border: 2px solid ${props => props.theme.colors.neutral93};
  border-radius: 50%;
`;
