import { createContext, FC, RefObject, useContext, useState } from "react";
import { DebugContextType } from './types';

const DebugContext = createContext<DebugContextType>({} as DebugContextType);
DebugContext.displayName = 'DebugContext';

declare global {
  interface Console {
    olog: any
  }
}

const DebugProvider: FC = ({ children }) => {
  const [consoleData, setConsoleData] = useState("");
  const [consoleElement, setConsoleElement] = useState<RefObject<HTMLDivElement>>();

  const clearConsole = () => {
    setConsoleData("");
  };

  const oldLogger = console.log;
  console.log = function (message: string) {
    oldLogger(message);
    if (typeof message == 'object') {
      if (consoleElement && consoleElement.current) {
        setConsoleData(consoleData + (JSON && JSON.stringify ? JSON.stringify(message) : message) + "<br />");
      }
    } else {
      if (consoleElement && consoleElement.current) {
        setConsoleData(consoleData + message + "<br />");
      }
    }
  };
  console.error = console.debug = console.info = console.log;

  const debugState = { consoleData };
  const debugActions = { setConsoleElement, clearConsole };
  const value = { debugState, debugActions };

  return (
    <DebugContext.Provider value={value}>
      {children}
    </DebugContext.Provider>
  );
};

export function useDebug() {
  const context = useContext(DebugContext);
  if (context === undefined) {
    throw new Error(`This component must be used within the DebugProvider`);
  }
  return context;
}

export default DebugProvider;
