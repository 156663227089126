import { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { IRecorder, RecorderContextType, SessionTimestamps, Timestamp } from "./types";
import { useSocketConnection } from "../SocketConnection/SocketConnectionProvider";
import { useSession } from "../Session/SessionProvider";
import { msToTimeDuration, adjustedNow } from "../../utils/helpers";

const RecorderContext = createContext<RecorderContextType>({} as RecorderContextType);
RecorderContext.displayName = 'RecorderContext';

/**
 * An uncontrolled (all state is internal) Recording component made to handle the state of a Recording session
 */
const RecorderProvider: FC<IRecorder> = ({ children }) => {
  const { socketState, socketActions } = useSocketConnection();
  const { toggleRecordingMode } = socketActions;
  const { sessionState } = useSession();
  const { sessionCountdowns, sessionEnd, sessionInProgress } = sessionState;
  const [audioData, setAudioData] = useState<number[]>(new Array(60).fill(0));
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingDuration, setRecordingDuration] = useState<string>("00:00:00");
  const [recordingId, setRecordingId] = useState<number>(0);
  const [sessionTimestamps, setSessionTimestamps] = useState<SessionTimestamps>([[null, null]]);
  const [filteredTimestamps, setFilteredTimestamps] = useState<SessionTimestamps>([[null, null]]);
  const [isSilent, setIsSilent] = useState(false);

  const timestampArraysAreEqual = (a: Array<Timestamp>, b: Array<Timestamp>) => {
    return a[0] === b[0] && a[1] === b[1];
  };

  // On initial page load, populate the sessionTimestamps array from the response to the initial GET request
  useEffect(() => {
    const timestampsInSync = (a: Array<Array<Timestamp>>, b: Array<Array<Timestamp>>) => {
      return (a.length === b.length) && timestampArraysAreEqual(a.slice(-1)[0], b.slice(-1)[0]);
    };
    const message = socketState.lastMessage;
    if (message && message['timecodes']) {
      const timecodes = message['timecodes'];
      if ('timecodes' in message && timecodes.length && !timestampsInSync(timecodes, sessionTimestamps)) {
        setSessionTimestamps(timecodes);
      }
    }
  }, [
    sessionTimestamps,
    setSessionTimestamps,
    socketState.lastMessage
  ]);


  useEffect(() => {
    // filter to timestamps from the time the token was validated
    setFilteredTimestamps(sessionTimestamps.filter(timestamps =>
      timestamps[0]
      && (timestamps[0] > socketState.tokenValidatedTimestamp! )
    )
    );
  }, [
    sessionTimestamps,
    socketState
  ]);

  // populate audioData buffer with energy values from lastMessage
  useEffect(() => {
    if (socketState.lastMessage !== null && sessionInProgress) {
      const message = socketState.lastMessage;
      if (message !== undefined && 'peak' in message) {
        const value = Math.trunc(message['peak']);

        setAudioData(prev => {
          // prune audioBuffer so it does not grow indefinitely and cause a browser crash
          if (prev.length === 60) {
            prev.shift();
          }
          return [...prev, value];
        });
      }
    }
  }, [sessionInProgress, socketState.lastMessage]);

  // set recording duration
  useEffect(() => {
    const calculateRecordingDuration = (timestamps: SessionTimestamps): string => {
      if (!timestamps.length) {
        return "00:00";
      }

      const lastRecordingTimestamps = timestamps[timestamps.length - 1];
      const recordingStart = lastRecordingTimestamps[0];
      const recordingEnd = lastRecordingTimestamps[1];

      return !recordingStart || !isRecording
        ? "00:00"
        : !recordingEnd
          ? msToTimeDuration(adjustedNow(socketState.timeOffset) - recordingStart)
          : msToTimeDuration(recordingEnd - recordingStart);
    };

    setRecordingDuration(calculateRecordingDuration(sessionTimestamps));
  }, [
    isRecording,
    sessionCountdowns.end,
    sessionTimestamps,
    socketState.timeOffset
  ]);

  const toggleRecording = useCallback(() => {
    toggleRecordingMode();
  }, [sessionTimestamps, socketState.timeOffset]);

  // clear timestamps for a completed session
  useEffect(() => {
    if (!sessionInProgress && sessionEnd && sessionEnd <= adjustedNow(socketState.timeOffset)) {
      if (isRecording) {
        // Ends recording if in progress to ensure 'saved' tab displays all tracks
        toggleRecording();
      }
      setIsRecording(false);
    }
  }, [isRecording, sessionEnd, sessionInProgress, socketState.timeOffset, toggleRecording]);

  // determine recording status from state of final sessionTimestamps element
  useEffect(() => {
    if (filteredTimestamps.length) {
      if (filteredTimestamps[filteredTimestamps.length - 1][0] !== null
        && filteredTimestamps[filteredTimestamps.length - 1][1] === null
      ) {
        setIsRecording(true);
      } else {
        setIsRecording(false);
      }

      // use length of filteredTimestamps array to enumerate recordings
      if (filteredTimestamps[0][0] !== null) {
        if (filteredTimestamps[filteredTimestamps.length - 1][0] === null) {
          setRecordingId(filteredTimestamps.length - 1);
        } else {
          setRecordingId(filteredTimestamps.length);
        }
      }
    }
  }, [filteredTimestamps]);


  useEffect(() => {
    const selection = audioData;
    const silentFrames = selection.filter((vu) => vu < 4);
    setIsSilent(silentFrames.length === selection.length);
  }, [audioData]);

  const state = { audioData, filteredTimestamps, isRecording, recordingId, recordingDuration, sessionInProgress, sessionCountdowns, sessionTimestamps, isSilent };
  // TODO: wrap actions in validation methods
  const actions = { toggleRecording };
  const value = { state, actions };

  return (
    <RecorderContext.Provider value={value}>
      {children}
    </RecorderContext.Provider>
  );
};

export function useRecorder() {
  const context = useContext(RecorderContext);
  if (context === undefined) {
    throw new Error(`This component must be used within the RecorderProvider`);
  }
  return context;
}

export default RecorderProvider;
