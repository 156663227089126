import { ISocketConnection, SocketConnectionContextType } from "./types";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import config from "../../config";
import { SessionTimestamps } from "../Recorder/types";
import {
  KroolConnectionState,
  useKroolWebsocket,
} from "../../socket/useKroolWebsocket";
import LogRocket from "logrocket";
import { getEnvBoolean } from "../../utils/env";
import * as Sentry from "@sentry/react";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";

const SocketConnectionContext = createContext<SocketConnectionContextType>(
  {} as SocketConnectionContextType
);
SocketConnectionContext.displayName = "SocketConnectionContext";

const SocketConnectionProvider: FC<ISocketConnection> = ({ children }) => {
  const [sessionId] = useState(uuidv4());
  const [error, setError] = useState<string>();
  const [timeOffset, setTimeOffset] = useState<number>(0);
  const [token, setToken] = useState("");
  const [tokenInvalidatedTimestamp, setTokenInvalidatedTimestamp] =
    useState<number>();
  const [tokenValidatedTimestamp, setTokenValidatedTimestamp] =
    useState<number>();
  const [websocketBaseUrl] = useState(
    (() => {
      if (config.websocket.base_url === "") {
        // use the current host
        const scheme = config.websocket.secure ? "wss" : "ws";
        return `${scheme}://${window.location.hostname}:${config.websocket.port}/ws/`;
      }
      // base_url should include scheme
      return `${config.websocket.base_url}:${config.websocket.port}/ws/`;
    })()
  );
  const [socketUrl] = useState(websocketBaseUrl);
  const [mockedReadyState, setMockedReadyState] =
    useState<KroolConnectionState>(KroolConnectionState.CLOSED);

  const updateToken = () => {
    const tokenInUrl = window.location.hash && window.location.hash.slice(1);
    const storedToken = localStorage.getItem("token");
    const newToken = tokenInUrl || storedToken || "";

    if (getEnvBoolean("REACT_APP_LOGROCKET")) {
      // get logrocket user traits from fqdn
      const traits: any = (() => {
        // check outer domain
        if (window.location.hostname.endsWith(".studio.pirate.com")) {
          // split first portion of fqdn on hyphen
          const hyphenSplit = window.location.hostname.split(".")[0].split("-");
          // expect 1 hyphen
          if (hyphenSplit.length === 2) {
            // expect 2 integers
            const siteId = parseInt(hyphenSplit[0]);
            const studioNumber = parseInt(hyphenSplit[1]);
            if (siteId && studioNumber) {
              return { siteId, studioNumber };
            }
          }
        }

        // if any of the above didn't work out, tag user with weird fqdn
        return { unknownFqdn: window.location.hostname };
      })();

      LogRocket.identify(newToken, traits);
    }

    setToken(newToken);
  };

  useEffect(() => {
    window.addEventListener("hashchange", updateToken);
    updateToken();
    return () => {
      window.removeEventListener("hashchange", updateToken);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [websocketBaseUrl, token]);

  if (config.isCloud) {
    window.addEventListener("focus", () => {
      if (token !== "") {
        SessionStateTracking.logReconnectToPirateWifi(token);
      }
      window.location.reload();
    });
  }

  const shouldConnect = !config.isCloud;
  const callbacks = {
    onopen: () => {
      sendJsonMessage({ id: sessionId, get: true });
    },
    onerror: (event: Event) => {
      console.log(event);
    },
    onclose: (event: CloseEvent) => {
      if (event.wasClean) {
        Sentry.captureException(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        Sentry.captureException(
          `[close] Connection died, code=${event.code} reason=${event.reason}`
        );
      }
      openSocket();
    },
  };

  const {
    openSocket,
    closeSocket,
    readyState,
    sendMessage,
    sendJsonMessage,
    lastMessage,
  } = useKroolWebsocket(socketUrl, token, callbacks, shouldConnect);

  useEffect(() => {
    sendJsonMessage({ id: sessionId, get: true });
  }, [sendJsonMessage, sessionId]);

  const socketConnectionStatus = readyState;

  useEffect(() => {
    if (shouldConnect) {
      const interval = setInterval(() => {
        sendMessage("keep-alive");
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [sendMessage, shouldConnect]);

  const toggleRecordingMode = useCallback(() => {
    sendJsonMessage({ id: sessionId, action: "toggleRecordingMode" });
  }, [sessionId, sendJsonMessage]);

  useEffect(() => {
    const now = Date.now();
    if (lastMessage) {
      // use k-rool's time as an anchor to overcome differences with client's clock
      if (lastMessage["time"]) {
        const difference = lastMessage["time"] - now;
        // allow for a short delay in receiving the message
        // if difference is larger, store as an offset to adjust session and recording timestamps
        Math.abs(difference) > 100
          ? setTimeOffset(difference)
          : setTimeOffset(0);
      }

      // only set the error if the message has an ID - otherwise VU messages override valid errors
      if (lastMessage["id"]) {
        setError(lastMessage["error"]);
      }

      if (lastMessage["invalidated_at"]) {
        setTokenInvalidatedTimestamp(lastMessage["invalidated_at"]);
        setError("unauthorized");
      }

      if (lastMessage["validated_at"]) {
        setTokenValidatedTimestamp(lastMessage["validated_at"]);
      }
    }
  }, [lastMessage]);

  const socketState = {
    error,
    socketConnectionStatus,
    sessionId,
    lastMessage,
    timeOffset,
    tokenInvalidatedTimestamp,
    tokenValidatedTimestamp,
    token,
  };
  const socketActions = { toggleRecordingMode, closeSocket, openSocket };
  const value = { socketState, socketActions };
  return (
    <SocketConnectionContext.Provider value={value}>
      {children}
    </SocketConnectionContext.Provider>
  );
};

export function useSocketConnection() {
  const context = useContext(SocketConnectionContext);
  if (context === undefined) {
    throw new Error(
      `This component must be used within the SocketConnectionProvider`
    );
  }
  return context;
}

export default SocketConnectionProvider;
