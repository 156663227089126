import { FormattedMessage } from "react-intl";
import { FlexibleContainer } from "scallywag-ui";
import * as Styled from "./styled";

const Feedback = () => {
  return (
    <Styled.FeedbackContainer>
      <FlexibleContainer
        display={"flex"}
        flexDirection={"column"}
        margin={"xl"}
        textAlign={"start"}
      >
        <h2>
          <FormattedMessage id="session.info.feedback.heading" />
        </h2>

        <FormattedMessage id="session.info.feedback.subheading" />
        <p>
          <FormattedMessage id="session.info.feedback.see_soon" />
        </p>
      </FlexibleContainer>
    </Styled.FeedbackContainer>
  );
};

export default Feedback;
