import { FormattedMessage } from "react-intl";
import { SpaceScaleValues } from "scallywag-ui";
import { ThemeColors } from "scallywag-ui/dist/lib/themes/types";
import { useHelp } from "../../contexts/Help/HelpProvider";
import { useSocketConnection } from "../../contexts/SocketConnection/SocketConnectionProvider";
import { SessionStateTracking } from "../../tracking/SessionStateTracking";
import * as Styled from "./styled";

type CustomerSupportButtonProps = {
  color?: ThemeColors;
  margin?: SpaceScaleValues;
};

const CustomerSupportButton = (props: CustomerSupportButtonProps) => {
  const { socketState } = useSocketConnection();
  const { helpActions } = useHelp();
  const primaryWhiteColor: ThemeColors = "primaryWhite";

  return (
    <Styled.SupportModalButton
      onClick={() => {
        helpActions.setCustomerSupportVisibility(true);
        if (socketState.token) {
          SessionStateTracking.logRequestCustomerSupport(socketState.token);
        }
      }}
      color={props.color ? props.color : primaryWhiteColor}
      margin={props.margin}
    >
      <FormattedMessage id={"help.customer_support.button"} />
    </Styled.SupportModalButton>
  );
};

export default CustomerSupportButton;
