import { Route, Switch } from "react-router-dom";
import RecorderProvider from "./contexts/Recorder/RecorderProvider";
import SessionProvider from "./contexts/Session/SessionProvider";
import Record from "./pages/Record/Record";
import SessionEnd from "./pages/SessionEnd/SessionEnd";
import config from "./config";
import { Debug } from "./pages/Debug/Debug";
import Feedback from "./pages/Feedback/Feedback";

export const routes = {
  record: "/record",
  debug: "/debug",
  sessionEnd: "/session-end",
  feedback: "/feedback",
};

const Router = () => {
  return (
    <Switch>
      <SessionProvider>
        <RecorderProvider>
          <Route path={["/", "/record"]} exact>
            <Record />
          </Route>
          {config.isCloud && (
            <>
              <Route path="/session-end">
                <SessionEnd />
              </Route>
              <Route path="/feedback">
                <Feedback />
              </Route>
            </>
          )}
          {config.debugMode && (
            <Route path="/debug">
              <Debug />
            </Route>
          )}
        </RecorderProvider>
      </SessionProvider>
    </Switch>
  );
};

export default Router;
