import styled from "styled-components";

export const DebugPageContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const DebugWindow = styled.div`
  height: calc(100% - ${props => props.theme.space.md});
  background: var(--theme-colors-offWhite);
  color: black;
  margin: ${props => props.theme.space.xs};
  padding: ${props => props.theme.space.sm} ${props => props.theme.space.md};
  text-align: left;
  line-height: 1.2;
`;